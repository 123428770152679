export default {
  items: [{
    id: 'dash',
    title: 'Dashboard',
    type: 'group',
    children: [{
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: 'feather icon-monitor'
    }]
  }, {
    id: 'reports',
    title: 'Reports',
    type: 'group',
    children: [{
      id: 'salesPeriod',
      title: 'Sales period',
      type: 'item',
      url: '/reports/sales-period',
      icon: 'feather icon-calendar'
    }, {
      id: 'orderedProductsPeriod',
      title: 'Ordered products',
      type: 'item',
      url: '/reports/ordered-products-period',
      icon: 'feather icon-box'
    }, {
      id: 'customersWhoOrderedProduct',
      title: 'Customer orders',
      type: 'item',
      url: '/reports/customers-who-ordered-product',
      icon: 'feather icon-box'
    }, {
      id: 'customersMetricsList',
      title: 'Customer metrics',
      type: 'item',
      url: '/reports/customers-metrics',
      icon: 'feather icon-box'
    }, {
      id: 'globalMetrics',
      title: 'Global metrics',
      type: 'item',
      url: '/reports/global-metrics',
      icon: 'feather icon-box'
    }, {
      id: 'driversReport',
      title: 'Drivers report',
      type: 'item',
      url: '/reports/routific',
      icon: 'feather icon-box'
    }, {
      id: 'driversTips',
      title: 'Drivers tips',
      type: 'item',
      url: '/reports/driver-tips',
      icon: 'feather icon-box'
    }
    ]
  }, {
    id: 'sales',
    title: 'Sales',
    type: 'group',
    children: [{
      id: 'sales-orders',
      title: 'Orders',
      type: 'item',
      url: '/sales/orders',
      icon: 'feather icon-clipboard'
    }, {
      id: 'sales-coupons',
      title: 'Coupons',
      type: 'item',
      url: '/sales/coupons',
      icon: 'feather icon-tag'
    }, {
      id: 'sales-promo',
      title: 'Promo',
      type: 'item',
      url: '/sales/promo',
      icon: 'feather icon-percent'
    }]
  }, {
    id: 'catalog',
    title: 'Products',
    type: 'group',
    children: [{
      id: 'catalog-products',
      title: 'Products',
      type: 'item',
      url: '/catalog/products',
      icon: 'feather icon-shopping-cart'
    },
    {
      id: 'catalog-arrivals',
      title: 'New Arrivals',
      type: 'item',
      url: '/catalog/newArrivals',
      icon: 'feather icon-plus-square'
    },
    // {
    //   id: 'catalog-departments',
    //   title: 'Departments',
    //   type: 'item',
    //   url: '/catalog/departments',
    //   icon: 'feather icon-box'
    // },
    // {
    //   id: 'catalog-categories',
    //   title: 'Categories',
    //   type: 'item',
    //   url: '/catalog/categories',
    //   icon: 'feather icon-box'
    // },
    {
      id: 'catalog-tree',
      title: 'Catalog',
      type: 'custom',
      url: '/catalog/departments',
      icon: 'feather icon-box'
    }]
  }, {
    id: 'stores',
    title: 'Stores',
    type: 'group',
    children: [{
      id: 'networks',
      title: 'Store Networks',
      type: 'item',
      url: '/stores/networks',
      icon: 'feather icon-bar-chart'
    },
    {
      id: 'stores',
      title: 'Stores',
      type: 'item',
      url: '/stores/stores',
      icon: 'feather icon-box'
    }]
  }, {
    id: 'accounts',
    title: 'Accounts',
    type: 'group',
    children: [{
      id: 'accounts-customers',
      title: 'Customers',
      type: 'item',
      url: '/accounts/customers',
      icon: 'feather icon-users'
    },
    {
      id: 'accounts-users',
      title: 'Users',
      type: 'item',
      url: '/accounts/users',
      icon: 'feather icon-user-plus'
    },
    {
      id: 'accounts-drivers',
      title: 'Drivers',
      type: 'item',
      url: '/accounts/drivers',
      icon: 'feather icon-navigation'
    },
    {
      id: 'vehicles',
      title: 'Vehicles',
      type: 'item',
      url: '/vehicles',
      icon: 'feather icon-box'
    },
    {
      id: 'delivery-profile',
      title: 'Delivery profile',
      type: 'item',
      url: '/delivery-profile',
      icon: 'feather icon-box'
    }
    ]
  }, {
    id: 'news-tips',
    title: 'News & Tips',
    type: 'group',
    children: [{
      id: 'news',
      title: 'News',
      type: 'item',
      url: '/news-tips/news',
      icon: 'feather icon-file-text'
    }, {
      id: 'tips',
      title: 'Tips',
      type: 'item',
      url: '/news-tips/tips',
      icon: 'feather icon-sun'
    }, {
      id: 'announcements',
      title: 'Announcements',
      type: 'item',
      url: '/announcements',
      icon: 'feather icon-box'
    }]
  }, {
    id: 'info',
    title: 'Information',
    type: 'group',
    children: [{
      id: 'info-page',
      title: 'Information',
      type: 'item',
      url: '/information',
      icon: 'feather icon-info'
    }, {
      id: 'email-page',
      title: 'Email templates',
      type: 'item',
      url: '/email-templates',
      icon: 'feather icon-mail'
    }]
  }, {
    id: 'preferences',
    title: 'Preferences',
    type: 'group',
    children: [{
      id: 'preferences-page',
      title: 'Preferences',
      type: 'item',
      url: '/preferences',
      icon: 'feather icon-settings'
    }]
  }]
}
